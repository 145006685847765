<template>
    <el-dialog class='grade-dialog' :title='`竞赛详情`' :visible.sync='show' :close-on-click-modal='false' width='1000px'
        :before-close='cancel'>
        <el-form ref='form' :model='formData' :rules='rules' label-width='100px'>
            <el-divider content-position="left">
                <h3>个人信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='16'>
                    <el-form-item label="姓名：" prop="userId">
                        {{ formData.realName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='部门：' prop='deptName'>
                        {{ formData.deptName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='工号：' prop='teacherNo'>
                        {{ formData.teacherNo }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider content-position="left">
                <h3>论文信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='学年：' prop='gradeName'>
                        {{ formData.gradeName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='学期：' prop='term'>
                        第{{ formData.term }}学期
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='参赛人类别：' prop='contestantType'>
                        {{ formData.contestantType }}
                    </el-form-item>
                </el-col>
                <el-col :span='8' v-if="this.formData.contestantType == '学生'">
                    <el-form-item label='学生姓名：' prop='studentName'>
                        {{ formData.studentName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8' v-else>
                    <el-form-item label='教师姓名：' prop='realName'>
                        {{ formData.realName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='竞赛名：' prop='contestName'>
                        {{ formData.contestName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='竞赛类别：' prop='contestCategory'>
                        {{ formData.contestCategory }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='获奖类别：' prop='awardCategory'>
                        {{ formData.awardCategory }}
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

import { getCompetitionByIdApi } from '@/api/achievement/competition-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog],
    data() {
        return {
            id: '',
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                contestantType: '学生',
                studentName: '',
                contestName: '',
                contestCategory: '',
                awardCategory: '',
                userType: 3
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.id) {
                this.getDataById(this.id, getCompetitionByIdApi)
            } else {
            }
        })
    },
    methods: {

    }
}
</script>
