<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="姓名"
                        :tool-list="['keyword', 'gradeName', 'term']">
        <el-form-item label="部门" v-permission="['achievement:competition:all']">
          <el-select v-model="queryInfo.deptId" clearable filterable size="small">
            <el-option v-for="( item, index ) in  departmentList " :key="index" :label="item.deptName"
                       :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="竞赛类别">
          <el-select v-model="queryInfo.contestCategory" clearable filterable size="small">
            <el-option v-for="( item, index ) in  contestCategoryList " :key="index" :label="item.type"
                       :value="item.type" />
          </el-select>
        </el-form-item>
        <el-form-item label="获奖类别">
          <el-select v-model="queryInfo.awardCategory" clearable filterable size="small">
            <el-option v-for="( item, index ) in  awardCategoryList " :key="index" :label="item.type"
                       :value="item.type" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button mb-1">
        <el-tag class="successNum">校级一等奖:{{ count.schoolLevelOne }};校级二等奖:{{
            count.schoolLevelTwo }};校级三等奖:{{ count.schoolLevelThree }};校级其他奖:{{ count.schoolLevelOther
          }};
        </el-tag>
        <el-tag class="successNum">市级一等奖:{{ count.cityLevelOne }};市级二等奖:{{
            count.cityLevelTwo }};市级三等奖:{{ count.cityLevelThree }};市级其他奖:{{ count.cityLevelOther
          }};
        </el-tag>
        <el-tag class="successNum">省级一等奖:{{ count.provinceLevelOne }};省级二等奖:{{
            count.provinceLevelTwo }};省级三等奖:{{ count.provinceLevelThree }};省级其他奖:{{ count.provinceLevelOther
          }};
        </el-tag>
        <el-tag class="successNum">国家级一等奖:{{ count.countryLevelOne }};国家级二等奖:{{
            count.countryLevelTwo }};国家级三等奖:{{ count.countryLevelThree }};国家级其他奖:{{ count.countryLevelOther
          }};
        </el-tag>
        <el-button @click="exportExcel()" type="success" size="small">
          竞赛成果导出
        </el-button>
      </div>
    </template>
    <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="realName" label="姓名" width="70"></el-table-column>
      <el-table-column prop="deptName" label="部门" min-width="120"></el-table-column>
      <el-table-column prop="teacherNo" label="工号"></el-table-column>
      <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
      <el-table-column prop="term" label="学期">
        <template v-slot="{ row }">
          {{ row.term ? `第${row.term}学期` : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="contestName" label="竞赛名称"></el-table-column>
      <el-table-column prop="contestCategory" label="竞赛类别"></el-table-column>
      <el-table-column prop="studentName" label="学生姓名"></el-table-column>
      <el-table-column prop="contestantType" label="参赛者类别"></el-table-column>
      <el-table-column prop="awardCategory" label="获奖类别"></el-table-column>
      <el-table-column label="操作" min-width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="showDialog(row.id)">
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <dialog-competition ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { pageCompetitionApi, delCompetitionApi, countCompetitionApi } from '@/api/achievement/competition-api.js'
import { contestCategoryList, awardCategoryList } from './components/option'
import dialogCompetition from './components/DialogCompetition'
import tableView from '@/vue/mixins/table-view'
import store from '@/store/index'

export default {
  name: 'competition',
  mixins: [tableView],
  components: { dialogCompetition },
  data () {
    return {
      queryInfo: {
        startDate: null,
        endDate: null,
        deptId: null,
        gradeName: null,
        term: null,
        contestCategory: null,
        awardCategory: null
      },
      contestCategoryList,
      awardCategoryList,
      departmentList: '',
      formData: {
        id: '',
        realName: '',
        deptName: '',
        teacherNo: '',
        term: '',
        studentName: '',
        contestName: '',
        contestCategory: '',
        awardCategory: ''
      },
      count: {}
    }
  },
  mounted () {
    this.renderTable()
  },
  methods: {
    delCompetitionApi,
    countCompetitionApi () {
      countCompetitionApi(this.queryInfo).then((res) => {
        this.count = res.data
      })
    },
    async renderTable (pageNum) {
      this.getAll()
      this.countCompetitionApi()
      this.getDepartment()
      await this.getTableData(pageCompetitionApi, pageNum)
    },
    // 查看全部的权限
    getAll () {
      const _permission = store.getters['login/getPermission']

      if (!(_permission.includes('achievement:competition:all'))) {
        this.queryInfo.deptId = this.$store.state.login.userInfo.deptId
      }
    },
    // 获取部门
    getDepartment () {
      this.$http.getDepartmentListQuery({}).then((res) => {
        this.departmentList = res.data
      })
    },
    // 导出
    exportExcel () {
      this.$http.exportExcelHttp.exportCompetition(this.queryInfo)
    }
  }

}
</script>

<style lang="scss" scoped>
.successNum {
  margin: 5px 20px 0 0;
}
</style>
