// 竞赛类别
export const contestCategoryList = [
    { type: '校级' },
    { type: '市级' },
    { type: '省级' },
    { type: '国家级' }
]
// 获奖类别
export const awardCategoryList = [
    { type: '一等奖' },
    { type: '二等奖' },
    { type: '三等奖' },
    { type: '其他奖' }
]

// 参赛者类别
export const contestantTypeList = [
    { type: '学生' },
    { type: '教师' }

]
